import React from "react"

import FontFaceInclude from "../FontFaceInclude"
import SpeakButton from "../SpeakButton"

import * as css from "./LanguageShowcase.module.scss"

const LanguageShowcase = ({
  languageCode,
  languageName,
  direction,
  fontFile,
  encoding,
  children,
}) => {
  const fontFamily = (fontFile || '').length > 0 && `Language-${languageCode || languageName}`;
  const fontStyle = fontFamily ? { fontFamily } : {}
  return (
    <>
      {fontFamily && fontFile && (
        <FontFaceInclude fontFamily={fontFamily} fontFile={fontFile} />
      )}
      <div
        className={[
          css.languageShowcase,
          direction === "rtl" ? css.languageShowcaseRtl : "",
        ].join(" ")}
        encoding={encoding || "utf-8"}
        style={fontStyle}
      >
        {children}
        <SpeakButton languageCode={languageCode} languageName={languageName}>
          {children}
        </SpeakButton>
      </div>
    </>
  )
}

export default LanguageShowcase;
