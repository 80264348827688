// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var buttonUnstyled = "LanguageShowcase-module--buttonUnstyled--dBLqD";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "LanguageShowcase-module--formRow--VgLeV";
export var languageShowcase = "LanguageShowcase-module--languageShowcase--+-MLI";
export var languageShowcaseRtl = "LanguageShowcase-module--languageShowcaseRtl--MPcRK";
export var listUnstyled = "LanguageShowcase-module--listUnstyled--fQj0H";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var screenreaderOnly = "LanguageShowcase-module--screenreaderOnly--DnGaH";
export var shadow = "LanguageShowcase-module--shadow--Y6+rY";
export var submitButton = "LanguageShowcase-module--submitButton--Fl0NY";
export var textFieldTitle = "LanguageShowcase-module--textFieldTitle--B3zEw";
export var textSmoothing = "LanguageShowcase-module--textSmoothing--vrKEc";
export var textUppercase = "LanguageShowcase-module--textUppercase--aWV34";