import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import LanguageShowcase from "../components/LanguageShowcase"
import Phrasify from '../components/Phrase';

const PotentialTranslation = ({ language, phraseId, translationsList = [] }) => {
  const foundTranslation = translationsList.find(tr => {
    return parseInt(tr?.node?.phrase?.fields?.id, 10) === parseInt(phraseId, 10);
  });
  if (foundTranslation) {
    return (
      <LanguageShowcase
        languageCode={language.isoCode}
        languageName={language.name}
        direction={language.direction}
        fontFile={
          language.font && language.font.length > 0
            ? `fonts/${language.name}/${language.font}.ttf`
            : null
        }
      >
        <Phrasify text={foundTranslation.node.fields.text} />
      </LanguageShowcase>
    );
  }
  return null;
};

const TableView = (props) => {
  const { data } = props;
  const fields = data?.languagesJson?.fields || {};
  const phrases = data?.allPhrasesJson?.edges || [];

  const translationsList = (data.allTranslationsJson.edges || []).sort(
    (a, b) => {
      if (!a.node.phrase || !b.node.phrase) {
        return 0;
      }
      
      if (a.node.phrase.fields.name < b.node.phrase.fields.name) {
        return -1
        
      }
      if (a.node.phrase.fields.name > b.node.phrase.fields.name) {
        return 1
      }
      return 0
    }
  )

  return (
    <Layout>
      <HeadMeta pageTitle={`Table view: ${fields.name}`} />
      <PageHeader>Table view: {fields.name}</PageHeader>
      <Breadcrumb
        route={[
          { name: "Multilingual library signage", href: "/" },
          { name: "Languages", href: "/language/" },
          { name: fields.name, href: `/${fields.slug}` },
          { name: "Table view", href: `/${fields.slug}/tableview/` },
        ]}
      />

      <table style={{
        width: `100%`,
        tableLayout: 'fixed',
        border: `1px solid #ccc`,
      }}>
        <thead>
          <tr>
            <th>English language phrase</th>
            <th>Translation to {fields.name}</th>
          </tr>
        </thead>
        <tbody>
          {phrases.map((phrase) =>
            <tr key={phrase.node.fields.id}>
              <td style={{ border: `1px solid #ccc` }}>
                <LanguageShowcase
                  languageCode={`en`}
                  languageName={`English`}
                  direction={`ltr`}
                  fontFile={null}
                >
                  <Phrasify text={phrase.node.fields.name} />
                </LanguageShowcase>
              </td>
              <td>
                <PotentialTranslation
                  language={fields}
                  phraseId={phrase.node.fields.id}
                  translationsList={translationsList}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Layout>
  )
}

export default TableView;

export const query = graphql`
  query TableViewPageQuery($languageID: Int) {
    languagesJson(fields: { id: {eq: $languageID}}) {
      fields {
        name
        nameTranslated
        isoCode
        encoding
        direction
        size
        font
        zip
        slug
      }
    }
    allPhrasesJson {
      edges {
        node {
          fields {
            name
            slug
            id
          }
        }
      }
    }
    allTranslationsJson(filter: {fields: {enabled: { eq: true }, languageID: {eq: $languageID}}}) {
      edges {
        node {
          fields {
            slug
            text
            id
          }
          language {
            fields {
              name
            }
          }
          phrase {
            fields {
              name
              id
            }
          }
        }
      }
    }
  }
`
