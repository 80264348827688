import React from "react"
import { StaticQuery, graphql } from "gatsby"

const FontFaceInclude = ({ fontFamily, fontFile }) => {
  if (!fontFamily || !fontFamily.length) {
    return <></>
  }

  const renderStyleBlock = data => {
    const urlPrefix = data.site.siteMetadata.baseAssetUrl
    const fontUrl = [urlPrefix.replace(/\/$/, ""), fontFile].join("/")
    return (
      <style type="text/css">
        {`
        @font-face {
          font-family: ${fontFamily};
          src: url('${fontUrl}') format('truetype');
        }
      `}
      </style>
    )
  }

  const query = graphql`
    query LanguageShowcaseQuery {
      site {
        siteMetadata {
          baseAssetUrl
        }
      }
    }
  `

  return <StaticQuery query={query} render={renderStyleBlock} />
}

export default FontFaceInclude;
