import React from "react"
import ReactDOMServer from 'react-dom/server';

import Button from "../Button"
import SVGIcon from "../SVGIcon"

import * as css from "./SpeakButton.module.scss"
import * as utilities from "../../styles/utilities.module.scss"

const SpeakButton = ({ languageName, languageCode, children }) => {
  const [voice, setVoice] = React.useState(null);
  const [speaking, setSpeaking] = React.useState(false);
  const [error, setError] = React.useState(false);

  const speech = React.useRef(null);

  const setupVoice = () => {
    if (!speech.current) {
      return
    }
    const voices = speech.current.getVoices();
    let voiceCandidates = [];

    // If we have a language code, pick by the voice matching the code,
    // and prefer defaults and remote services where possible.
    if (languageCode) {
      voiceCandidates = voices
        .filter(v => v.lang.indexOf(languageCode) === 0)
        .sort((a, b) => {
          if (a.default) {
            return -1
          }
          if (b.default) {
            return 1
          }
          if (!a.localService) {
            return -1
          }
          if (!b.localService) {
            return 1
          }
          return 0
        });
    }
    if (voiceCandidates && voiceCandidates.length) {
      setVoice(voiceCandidates[0]);
      return;
    }
    setVoice(null)
  }

  const speak = speakString => {
    if (!speech.current) {
      return
    }
    const utterThis = new SpeechSynthesisUtterance(speakString)
    utterThis.voice = voice
    utterThis.pitch = 1
    utterThis.rate = 0.75
    utterThis.addEventListener("start", () => setSpeaking(true), false)
    utterThis.addEventListener("end", () => setSpeaking(false), false)
    utterThis.addEventListener(
      "error",
      e => {
        console.error(e)
        setSpeaking(false)
        setError(true)
      },
      false
    )
    speech.current.speak(utterThis)
  }

  const playSpeech = () => {
    if (!speech.current) {
      return;
    }

    const plainText = ReactDOMServer.renderToString(children);
    if (typeof plainText !== "string") {
      console.error("Child element of LanguageShowcase must be a string or resolve to a string.");
      return;
    }

    speak(plainText);
  }

  React.useEffect(() => {
    if (!speech.current && window) {
      speech.current = window.speechSynthesis || null
    }
    if (speech.current) {
      setupVoice()
      if (speech.current.onvoiceschanged !== undefined) {
        speech.current.onvoiceschanged = setupVoice
      }
    }
  })

  if (speech.current && voice !== null && speaking !== true && error !== true) {
    return (
      <Button className={css.speakButton} onClick={playSpeech}>
        <SVGIcon
          name="audio"
          size="sm"
          alt="Speaker cone image"
          aria-hidden="true"
        />
        <span className={utilities.screenreaderOnly}>
          Play audio in {languageName}
        </span>
      </Button>
    )
  }
  return <></>
};

export default SpeakButton;
